import { ScrollView } from 'react-native';
import React, { useContext, useState, useEffect,useRef  } from 'react';
import { AuthContext } from '../context/AuthContext';
import { AxiosContext } from '../context/AxiosContext';
import Gradient from '../assets/Icons/Gradient';
import { config } from "@gluestack-ui/config";
import {
  FormControl, FormControlLabel, FormControlLabelText, Input, InputField, FormControlError, FormControlErrorText, FormControlErrorIcon, FormControlHelper,
  FormControlHelperText, AlertCircleIcon, ButtonText, ButtonIcon, AddIcon, Button, UnlockIcon, TrashIcon,
  Select, SelectTrigger, SelectInput, SelectIcon, SelectPortal, SelectBackdrop, SelectContent, SelectDragIndicatorWrapper, SelectDragIndicator, SelectItem,
  ChevronDownIcon, Icon, AlertDialog, AlertDialogBackdrop, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, AlertDialogFooter, Center,
  showAlertDialog, AlertDialogOverlay, AlertDialogDescription, AlertDialogTitle, AlertDialogAction, AlertDialogCancel, AlertDialogIcon, AlertDialogLoading,
  Heading, CloseIcon, ButtonGroup, Textarea, TextareaField, Checkbox, CheckboxLabel, CheckboxLabelText, Radio, RadioGroup, RadioLabel, RadioLabelText

} from '@gluestack-ui/themed';
import { Box, GluestackUIProvider, Text } from '@gluestack-ui/themed';
import { storeTenant, checkLastLoginSaved, storeLoginData, getAllLoginData, storeLastLoginData, getLastLoginData } from '../context/LoginStorage';
import { useToast } from "react-native-toast-notifications";

import baseConfig from '../config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Spinner from '../componentes/Spinner';
import SelectDropdown from 'react-native-select-dropdown';


const Login = () => {

 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [tenant, setTenant] = useState('');
  const [tipo, setTipo] = useState({value: 'propietario', nombre: 'Propietario'});
  const [loading, setLoading] = useState(false);

  const authContext = useContext(AuthContext);
  const { publicAxios } = useContext(AxiosContext);
  const toast = useToast();
  const [allLoginData, setAllLoginData] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(false);
  const [selectedCuenta, setSelectedCuenta] = useState(null);
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const [autoLogin, setAutologin] = useState(false);
  const isFirstRender = useRef(true);

  const tipoIngreso = [
    {value: 'propietario', nombre: 'Propietario'},
    {value: 'inquilino', nombre: 'Inquilino'},
  ];

useEffect(() => {
  if (isFirstRender.current) {
    isFirstRender.current = false;
    return; // 👈️ return early if initial render
  }
  onLogin();
}, [autoLogin]); // 👈️ add state variables you want to track



  useEffect(() => {
    getLoginData();
    lastLoginData();
  }, []);



  const getLoginData = async () => {
    const loginData = await getAllLoginData();
    setAllLoginData(loginData);
  };

  const lastLoginData = async () => {
    const loginData = await getLastLoginData();
    //console.log(loginData);
    if (loginData != null) {
      setEmail(loginData.email);
      setPassword(loginData.password);
      setTenant(loginData.tenant);
      setTipo(loginData.tipo)
      setAutologin(true);
    }
  }

  const onTenantChange = async (key) => {

    const dataLogin = await AsyncStorage.getItem(key);
    const loginData = JSON.parse(dataLogin);

    setEmail(loginData.email);
    setPassword(loginData.password);
    setTenant(loginData.tenant);
    setTipo(loginData.tipo)
    setSelectedTenant(true);
    setSelectedCuenta(key);
  }

  const onTipoChange = async (key) => {
    console.log(key);
    setTipo(key);
  }

  const borrarCuenta = async () => {

    if (selectedTenant === null) {
      toast.show('Debe seleccionar una cuenta', {
        type: "warning",
      });
      return;
    }

    await AsyncStorage.removeItem(selectedCuenta);
    getLoginData();
    setSelectedTenant(false);
    setSelectedCuenta(null);
    setEmail('');
    setPassword('');
    setTenant('');
    setTipo(null);

    toast.show('La cuenta se ha quitado', {
      type: "success",
    });

  }



  const onLogin = async () => {

    if (email === '' || password === '' || tenant === '' || tipo === null) {
      toast.show('Debe ingresar todos los datos', {
        type: "warning",
      });
      return;
    }

    setLoading(true);
    
    await storeTenant(tenant);
    const tenantName = await AsyncStorage.getItem('tenant');

    let url = baseConfig.pre + tenantName + baseConfig.apiURL + '/login';
    let administracion = 'Administracion';


    try {
      const response = await publicAxios.post(url, {
        email,
        password,
        tipo: tipo.value,
      });

      administracion = response.data.administracion;

      const loginSaved = await checkLastLoginSaved(tenantName);

      let loginData = {
        tenant: tenantName,
        email: email,
        password: password,
        administracion: administracion,
        tipo: tipo,
        key: loginSaved.key
      }

      await storeLoginData(loginSaved.key, loginData);

      await storeLastLoginData({
        tenant: tenantName,
        email: email,
        password: password,
        tipo: tipo,
        administracion: administracion,
      });

      
      const { accessToken, refreshToken } = response.data;
      authContext.setAuthState({
        accessToken,
        refreshToken,
        authenticated: true,
      });

      /*
      await Keychain.setGenericPassword(
        'token',
        JSON.stringify({
          accessToken,
          refreshToken,
        }),
      );
      */

    } catch (error) {
      if (error.message != "Keychain Error: Cannot read property 'getGenericPasswordForOptions' of null"
      || error.message != "TypeError: Cannot read property 'setGenericPasswordForOptions' of null") {
        console.log("aca"+error);
        toast.show('Error al ingresar', {
          type: "warning",
        });
      }
      setLoading(false);
    }
    finally {
      setLoading(false);
    }

  };


  if (loading === true) {
    return <Spinner />;
  }

  return (
    <GluestackUIProvider config={config}>
      <Box flex={1} backgroundColor="$black">
        <ScrollView
          style={{ height: '100%' }}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Box
            position="absolute"
            sx={{
              '@base': {
                h: 500,
                w: 500,
              },
              '@lg': {
                h: 700,
                w: 700,
              },
            }}
          >
            <Gradient />
          </Box>
          <Box
            height="60%"
            sx={{
              '@base': {
                my: '$16',
                mx: '$5',
                height: '80%',
              },
              '@lg': {
                my: '$24',
                mx: '$32',
              },
            }}
            justifyContent="space-between"
            alignItems="center"
          >

            <Box
              justifyContent="center"
              alignItems="center">

              <Box
                bg="#64748B33"
                py="$2"
                px="$6"
                rounded="$full"
                alignItems="center"
                marginTop={20}
                sx={{
                  '@base': {
                    flexDirection: 'column',
                  },
                  '@sm': {
                    flexDirection: 'row',
                  },
                  '@md': { alignSelf: 'flex-start' },
                }}
              >
                <Text color="$white" fontWeight="$normal">
                  <Text color="$white" fontWeight="$bold">Kavanagh Cloud</Text>  La app de tu consorcio
                </Text>

              </Box>

              {allLoginData.length === 0 ? null :

              <Box
                justifyContent="center"
                alignItems="center">

                <Box
                  bg="#64748B33"
                  px="$6"
                  rounded="$full"
                  alignItems="center"
                  marginTop={20}
                  sx={{
                    '@base': {
                      flexDirection: 'column',
                    },
                    '@sm': {
                      flexDirection: 'row',
                    },
                    '@md': { alignSelf: 'flex-start' },
                  }}
                >
  
                  <SelectDropdown
                    buttonStyle={{ width: 300, backgroundColor: 'rgba(255,255,255,0.1)', borderRadius: 15 }}
                    buttonTextStyle={{ color: 'white' }}
                    data={allLoginData}
                    defaultButtonText={"Selecciona tu administrador"}
                    onSelect={(selectedItem, index) => {
                      onTenantChange(selectedItem.key);
                    }}
                    buttonTextAfterSelection={(selectedItem, index) => {
                      // text represented after item is selected
                      // if data array is an array of objects then return selectedItem.property to render after item is selected
                      return selectedItem.administracion
                    }}
                    rowTextForSelection={(item, index) => {
                      // text represented for each item in dropdown
                      // if data array is an array of objects then return item.property to represent item in dropdown
                      return item.administracion
                    }}
                  />

                </Box>
              </Box>
              }

              <Box justifyContent="center" mt={10}
                alignItems="center">
                <Text size="6xl" fontSize={28} color="$white" fontWeight="$normal">
                  Ingreso
                </Text> 

                {!selectedTenant ? null :

                <Center h={25} mb={10}>
                  <Button onPress={() => setShowAlertDialog(true)} size="sm" action="primary"  variant="outline" borderColor='red'>
                    <ButtonText color='red'>Quitar cuenta actual</ButtonText>
                  </Button>
                  <AlertDialog
                    isOpen={showAlertDialog}
                    onClose={() => {
                      setShowAlertDialog(false)
                    }}
                  >
                    <AlertDialogBackdrop />
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <Heading size="lg">Quitar la cuenta actual</Heading>
                        <AlertDialogCloseButton>
                          <Icon as={CloseIcon} />
                        </AlertDialogCloseButton>
                      </AlertDialogHeader>
                      <AlertDialogBody>
                        <Text size="sm">
                        ¿Confirmas que quieres quitar la cuenta?
                        podrás agregarla nuevamente ingresando los datos
                        </Text>
                      </AlertDialogBody>
                      <AlertDialogFooter>
                        <ButtonGroup space="lg">
                          <Button
                            variant="outline"
                            action="secondary"
                            onPress={() => {
                              setShowAlertDialog(false)
                            }}
                          >
                            <ButtonText>Cancelar</ButtonText>
                          </Button>
                          <Button
                            bg="$error600"
                            action="negative"
                            onPress={() => {
                              borrarCuenta();
                            }}
                          >
                            <ButtonText>Quitar</ButtonText>
                          </Button>
                        </ButtonGroup>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </Center>
                }


                <Box h="$32" w="$72">

                <FormControlLabel mb="$1">
                      <FormControlLabelText color="$white">Ingresar como</FormControlLabelText>
                    </FormControlLabel>
                <SelectDropdown
                    buttonStyle={{ width: 300, backgroundColor: 'rgba(255,255,255,0.1)'}}
                    buttonTextStyle={{ color: 'white' }}
                    data={tipoIngreso}
                    defaultButtonText={"Seleciona como ingresar"}
                    defaultValue={tipo}
                    onSelect={(selectedItem, index) => {
                      onTipoChange(selectedItem);
                    }}
                    buttonTextAfterSelection={(selectedItem, index) => {
                      // text represented after item is selected
                      // if data array is an array of objects then return selectedItem.property to render after item is selected
                      return selectedItem.nombre
                    }}
                    rowTextForSelection={(item, index) => {
                      // text represented for each item in dropdown
                      // if data array is an array of objects then return item.property to represent item in dropdown
                      return item.nombre
                    }}
                  />

                  <FormControl
                    mt={15}
                    size="md"
                    isDisabled={false}
                    isInvalid={false}
                    isRequired={true}
                  >
                    <FormControlLabel mb="$1">
                      <FormControlLabelText color="$white">Código de su administrador</FormControlLabelText>
                    </FormControlLabel>
                    <Input>
                      <InputField color="$white" key="tenant" value={tenant} type="text" placeholder="Ingrese el código" onChangeText={(text) => setTenant(text)} />
                    </Input>
                  </FormControl>

                  <FormControl
                    mt={15}
                    size="md"
                    isDisabled={false}
                    isInvalid={false}
                    isRequired={true}
                  >
                    <FormControlLabel mb="$1">
                      <FormControlLabelText color="$white">Email</FormControlLabelText>
                    </FormControlLabel>
                    <Input>
                      <InputField color="$white" key="email" value={email} type="email" placeholder="Ingrese su email" onChangeText={(text) => setEmail(text)} />
                    </Input>
                  </FormControl>

                  <FormControl
                    mt={15}
                    size="md"
                    isDisabled={false}
                    isRequired={true}
                  >
                    <FormControlLabel mb="$1">
                      <FormControlLabelText color="$white">Password</FormControlLabelText>
                    </FormControlLabel>
                    <Input>
                      <InputField color="$white" key="password" value={password} type="password" placeholder="Ingrese su password" onChangeText={(text) => setPassword(text)} />
                    </Input>
                  </FormControl>
                </Box>
              </Box>

              <Box h="$32" w="$72" mt={200}>
                <Button
                  size="md"
                  variant="outline"
                  action="primary"
                  isDisabled={false}
                  isFocusVisible={false}
                  onPress={() => onLogin()}
                >
                  <ButtonText>Ingresar</ButtonText>
                  <ButtonIcon as={UnlockIcon} />
                </Button>
              </Box>
            </Box>
          </Box>

        </ScrollView>
      </Box>
    </GluestackUIProvider>
  );


};

export default Login;