import React from "react";
import { Svg, Path } from "react-native-svg";
import { MenuIcon, Icon } from "@gluestack-ui/themed"

const ReciboIcon = () => {
  return (
    <Icon color="white" as={MenuIcon} size="lg"/>
  );
};

export default ReciboIcon;
