import React from "react";
import { Svg, Path } from "react-native-svg";
import { BellIcon, Icon } from "@gluestack-ui/themed"

const SvgComponent = () => {
  return (
    <Icon color="white" as={BellIcon} size="lg" />
  );
};

export default SvgComponent;
