import React from 'react';
import { ScrollView } from 'react-native';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import Gradient from '../assets/Icons/Gradient';
import { Box, GluestackUIProvider } from '@gluestack-ui/themed';
import { config } from "@gluestack-ui/config";

const Spinner = () => (
  <GluestackUIProvider config={config}>
    <Box flex={1} backgroundColor="$black">
      <ScrollView
        style={{ height: '100%' }}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Box
          position="absolute"
          sx={{
            '@base': {
              h: 500,
              w: 500,
            },
            '@lg': {
              h: 700,
              w: 700,
            },
          }}
        >
          <Gradient />
        </Box>

        <Box
          justifyContent="center"
          alignItems="center">
          <Box mt={"90%"}
            justifyContent="space-between"
            alignItems="center"
          >
            <ActivityIndicator size="large" color="#ca50b0" />
          </Box>
        </Box>

      </ScrollView>
    </Box>
  </GluestackUIProvider>
);



export default Spinner;