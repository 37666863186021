import AsyncStorage from '@react-native-async-storage/async-storage';

//store the login data in async storage
const storeLoginData = async (key, value) => {
    try {
        const jsonValue = JSON.stringify(value);
        await AsyncStorage.setItem(key, jsonValue);
    } catch (e) {
        // saving error
    }
};

const storeLastLoginData = async (value) => {
    try {
        const jsonValue = JSON.stringify(value);
        await AsyncStorage.setItem("lastLogin", jsonValue);
    } catch (e) {
        // saving error
    }
};

const clearLastLoginData = async () => {
    try {
        await AsyncStorage.removeItem("lastLogin");
    } catch (e) {
        // saving error
    }
}

const getLastLoginData = async () => {
    try {
        const jsonValue = await AsyncStorage.getItem("lastLogin");
       //(jsonValue);
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
        // error reading value
    }
}



//check the last stored login of 50 logins
const checkLastLoginSaved = async (tenantName) => {
    for (let i = 0; i < 50; i++) {
        const jsonValue = await AsyncStorage.getItem('login_' + i);

            const loginData = JSON.parse(jsonValue);
         
            if (loginData != null) {
                if (loginData.tenant === tenantName) {
                    return {'existe':true, 'key':'login_' + i};
                }
            }
            else {
                return {'existe':false, 'key':'login_' + i};
            }
      
    }
};

const getAllLoginData = async () => {

    let loginData = Array();

    for (let i = 0; i < 50; i++) {
        const jsonValue = await AsyncStorage.getItem('login_' + i);
        if (jsonValue != null) {
            let json = JSON.parse(jsonValue);
            //console.log(json);
            loginData.push({
                'key': json.key,
                'administracion': json.administracion
            });
        }
    }
    return loginData;
}

const storeTenant = async (value) => {
    try {
        await AsyncStorage.setItem('tenant', value);
    } catch (e) {
        // saving error
    }
};

const storeUnidad = async (value) => {

    try {
        const jsonValue = JSON.stringify(value);
        await AsyncStorage.setItem("unidad", jsonValue);
    } catch (e) {
        // saving error
    }
}


export { storeLoginData, checkLastLoginSaved, storeTenant, getAllLoginData, storeLastLoginData, getLastLoginData, clearLastLoginData, storeUnidad };