import React from "react";
import { Svg, Path } from "react-native-svg";

const SvgComponent = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M9.5 16.75C9.5 16.3358 9.16421 16 8.75 16C8.33579 16 8 16.3358 8 16.75V18.25C8 18.6642 8.33579 19 8.75 19C9.16421 19 9.5 18.6642 9.5 18.25V16.75ZM12 12C12.4142 12 12.75 12.3358 12.75 12.75V18.25C12.75 18.6642 12.4142 19 12 19C11.5858 19 11.25 18.6642 11.25 18.25L11.25 12.75C11.25 12.3358 11.5858 12 12 12ZM16 14.75C16 14.3358 15.6642 14 15.25 14C14.8358 14 14.5 14.3358 14.5 14.75V18.25C14.5 18.6642 14.8358 19 15.25 19C15.6642 19 16 18.6642 16 18.25V14.75ZM13.585 2.586L19.414 8.414C19.789 8.789 20 9.298 20 9.828V20C20 21.104 19.104 22 18 22H6C4.896 22 4 21.104 4 20V4C4 2.896 4.896 2 6 2H12.172C12.1999 2 12.2271 2.00371 12.2542 2.00741C12.2738 2.01008 12.2933 2.01274 12.313 2.014C12.528 2.029 12.74 2.07 12.937 2.152C12.9944 2.17648 13.0488 2.20797 13.103 2.23933C13.1197 2.24897 13.1363 2.25859 13.153 2.268C13.1685 2.27647 13.1845 2.28426 13.2005 2.29207C13.2281 2.30548 13.2557 2.31894 13.281 2.336C13.359 2.389 13.429 2.452 13.5 2.516C13.5115 2.5262 13.5238 2.53567 13.5363 2.5452C13.5531 2.55808 13.57 2.57105 13.585 2.586ZM18 20.5C18.276 20.5 18.5 20.275 18.5 20V10H14C12.896 10 12 9.104 12 8V3.5H6C5.724 3.5 5.5 3.725 5.5 4V20C5.5 20.275 5.724 20.5 6 20.5H18ZM17.378 8.5L13.5 4.621V8C13.5 8.275 13.724 8.5 14 8.5H17.378Z"
        fill="#FAFAFA"
      />
    </Svg>
  );
};

export default SvgComponent;
