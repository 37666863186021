import { Box, GluestackUIProvider, Text } from '@gluestack-ui/themed';
import dayjs from "dayjs";

     const NotificacionCard = (props) => {
      return (
        <Box
          flexDirection="column"
          borderWidth={1}
          borderColor="$borderDark700"
          sx={{
            _web: {
              flex: 1,
            },
          }}
          m="$2"
          p="$4"
          rounded="$md"
        >
               <Box>
            <Text fontSize={18} color="$white" fontWeight="100" ml="$2" textAlign='center'>
            {dayjs(props.notificacion.desde).format("DD-MM-YYYY")}
            </Text>
          </Box>

          <Box alignItems="center" display="flex" flexDirection="row">
            <Text fontSize={22} color="$white" fontWeight="500" ml="$2">
              {props.notificacion.titulo}
            </Text>
          </Box>
        </Box>
      );
    };

    export default NotificacionCard;
