import { Box, GluestackUIProvider, Text } from '@gluestack-ui/themed';
import { StyleSheet, View } from 'react-native';
import dayjs from "dayjs";

const numberFormat = (value) =>
  new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'USD'
  }).format(value);

const ExpensaCard = ({ expensa }) => {
  return (
    <Box
      margin={5}
      flexDirection="scolumn"
      borderWidth={1}
      borderColor="$borderDark700"
      sx={{
        _web: {
          flex: 1,
        },
      }}

      rounded="$md"
      width={300}
      alignItems="center" // Add this line
    >

        <Box
        borderTopWidth={1}
        borderTopColor="$borderDark700"
        borderStartStartRadius={5}
        borderStartEndRadius={5}
        width={300}
        backgroundColor='rgba(185, 185, 185, 0.2)'
        alignItems="center" // Add this line
      >
        <Text fontSize={20} color="$white" fontWeight="500" ml="$2">
        {expensa.año}-{expensa.mes.toString().padStart(2, '0')}
          {expensa.extraordinaria == 0 ? null : <Text color="$blue500"> (Extraordinaria)</Text>}
        </Text>
      </Box>

      <Box mt={5}>
        <View style={styles.itemContainer}>
          <Text style={styles.titulo}>Fecha de cierre:</Text>
          <Text style={[styles.fecha, styles.negrita]}>{dayjs(expensa.fecha_cierre).format("DD-MM-YYYY")}</Text>
        </View>

        <View style={styles.itemContainer}>
          <Text style={styles.titulo}>1er Vencimiento:</Text>
          <Text style={[styles.fecha, styles.negrita]}>{dayjs(expensa.fecha_vencimiento_1).format("DD-MM-YYYY")}</Text>
        </View>

        <View style={styles.itemContainer}>
          <Text style={styles.titulo}>2do Vencimiento:</Text>
          <Text style={[styles.fecha, styles.negrita]}>{dayjs(expensa.fecha_vencimiento_2).format("DD-MM-YYYY")}</Text>
        </View>
      </Box>

      <Box
        borderTopWidth={1}
        borderTopColor="$borderDark700"
        width={300}
        alignItems="center" // Add this line
      >
        <Text color="$white" mt="$1" mb="$1">
          <Text fontWeight='bold'> Total: </Text> {numberFormat(expensa.total)}
        </Text>
      </Box>
    </Box>
  );
};

export default ExpensaCard;


const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  titulo: {
    color: 'white',
    marginRight: 10,
  },
  fecha: {
    color: 'white',
  },
  negrita: {
    fontWeight: 'bold',
  },
});
