import { Box, GluestackUIProvider, Text } from '@gluestack-ui/themed';

const numberFormat = (value) =>
  new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'USD'
  }).format(value);

    const UnidadCard = ({name, desc, saldo }) => {
      return (
        <Box
          flexDirection="scolumn"
          borderWidth={1}
          borderColor="$borderDark700"
          sx={{
            _web: {
              flex: 1,
            },
          }}
          m="$2"
          p="$4"
          rounded="$md"
          width={300}
          alignItems="center" // Add this line
        >
          <Box alignItems="center" display="flex" flexDirection="row">
            <Text fontSize={22} color="$white" fontWeight="500" ml="$2">
              {name}
            </Text>
          </Box>
          <Text color="$textDark400" mt="$2">
            {desc}
          </Text>

          <Box
          borderTopWidth={1}
          borderTopColor="$borderDark700"
          width={300}
          alignItems="center" // Add this line
        >
         <Text color="$white" mt="$2">
         <Text fontWeight='bold'> Saldo Capital: </Text> {numberFormat(saldo)}
          </Text>
          </Box>
        </Box>
      );
    };

    export default UnidadCard;
