
import { Box, GluestackUIProvider, Text, Button, ButtonText, Input, InputField, FormControlLabel, FormControlLabelText, FormControl, CalendarDaysIcon } from '@gluestack-ui/themed';
import Gradient from '../assets/Icons/Gradient';
import { ScrollView, View, ActivityIndicator } from 'react-native';
import React, { useState, useEffect, useContext } from 'react';
import UnidadCard from '../componentes/UnidadCard';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SafeAreaProvider } from "react-native-safe-area-context";
import { DatePickerModal } from 'react-native-paper-dates'
import { useToast } from "react-native-toast-notifications";
import { AuthContext } from '../context/AuthContext';
import { AxiosContext } from '../context/AxiosContext';
import dayjs from "dayjs";
import baseConfig from '../config';
import { es, registerTranslation } from 'react-native-paper-dates'
registerTranslation('es', es)


const AvisoPago = (props) => {

    const toast = useToast();
    const axiosContext = useContext(AxiosContext);
    const authContext = useContext(AuthContext);

    const volver = () => {
        props.setPage('unidad');
    }

    const [unidadData, setUnidadData] = useState([]);
    const [open, setOpen] = React.useState(false);

    const [fecha, setFecha] = useState(new Date());
    const [comprobante, setComprobante] = useState('');
    const [importe, setImporte] = useState('');
    const [loading, setLoading] = useState(false);

    //date picker
    const onDismissSingle = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const onConfirmSingle = React.useCallback(
        (params) => {
            setOpen(false);
            setFecha(params.date);
        },
        [setOpen, setFecha]
    );

    const enviarAviso = async () => {

        //compruebo que los datos esten completos
        if (comprobante == '' || importe == '' || fecha == '') {
            toast.show('Debe completar los datos', {
                type: "warning",
            });
            return;
        }

        //compruebo que la fecha tenga el formato correcto
        if (!dayjs(fecha).isValid()) {
            toast.show('La fecha debe tener el formato correcto', {
                type: "warning",
            });
            return;
        }

        //compruebo que el importe sea numerico, positivo, mayor a cero y que tenga dos decimales separados por punto y que no tenga puntos en los miles
        if (!importe.match(/^[0-9]+(\.[0-9]{1,2})?$/)) {
            toast.show('El importe debe ser numerico, positivo y tener dos decimales separado por punto', {
                type: "warning",
            });
            return;
        }

        setLoading(true);

        const tenantName = await AsyncStorage.getItem('tenant');
        let url = baseConfig.pre + tenantName + baseConfig.apiURL + '/avisopago';

        const data = {
            unidad_id: unidadData.id,
            fecha: fecha,
            comprobante: comprobante,
            importe: importe,
            edificio_id: unidadData.edificio_id
        }

        try {
            const response = await axiosContext.authAxios.post(url, data);

            if (response.data.success) {

                setFecha(new Date());
                setComprobante('');
                setImporte('');

                toast.show('Aviso de pago enviado.', {
                    type: "success",
                });

            }
            else {
                toast.show('No se pudo enviar el aviso, intentalo mas tarde', {
                    type: "warning",
                });
            }

        } catch (error) {
            toast.show('No se pudo enviar el aviso, intentalo mas tarde', {
                type: "warning",
            });
        }
        finally {
            setLoading(false);
        }
    }

    const loadUnidadData = async () => {
        const unidadData = await AsyncStorage.getItem('unidad');
        setUnidadData(JSON.parse(unidadData));
    }

    useEffect(() => {
        loadUnidadData();
    }, []);

    return (
        <Box flex={1} backgroundColor="$black">
            <ScrollView
                style={{ height: '100%' }}
                contentContainerStyle={{ flexGrow: 1 }}
            >
                <Box
                    position="absolute"
                    sx={{
                        '@base': {
                            h: 500,
                            w: 500,
                        },
                        '@lg': {
                            h: 700,
                            w: 700,
                        },
                    }}
                >
                    <Gradient />
                </Box>
                <Box
                    height="60%"
                    sx={{
                        '@base': {
                            my: '$16',
                            mx: '$5',
                            height: '80%',
                        },
                        '@lg': {
                            my: '$24',
                            mx: '$32',
                        },
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                >


                    <Box
                        justifyContent="center"
                        alignItems="center">

                        <Text color="$white" fontWeight="$normal">
                            Aviso de pago de
                        </Text>

                        <UnidadCard
                            name={unidadData?.piso + ' ' + unidadData?.departamento + ' - UF ' + unidadData?.uf?.toString().padStart(3, '0')}
                            desc={unidadData?.edificio_direccion + ', ' + unidadData?.edificio_localidad}
                            saldo={unidadData?.saldo}
                        />

                        {loading ? <ActivityIndicator size="large" color="#ca50b0" /> : null}

                        {loading ? null :
                            <Box
                                justifyContent="center"
                                alignItems="center">
                                <Text color="$white" fontWeight="$normal" textAlign='center'>
                                    Complete los datos para enviar el aviso de pago
                                </Text>


                                <Box h="$32" w="$72" mt={15}>
                                    <FormControl
                                        mt={10}
                                        size="md"
                                        isRequired={true}
                                        readOnly={true}
                                    >
                                        <FormControlLabel mb="$1">
                                            <FormControlLabelText color='$white'>Fecha del pago</FormControlLabelText>
                                        </FormControlLabel>
                                        <Input>
                                            <InputField color='$white' type="text" value={dayjs(fecha).format("DD-MM-YYYY")} placeholder="Ingrese el nro de comprobante" />
                                        </Input>

                                        <Button onPress={() => setOpen(true)} uppercase={false} mode="outlined" variant="outline"
                                            action="primary">
                                            <Text color='$white'><CalendarDaysIcon color='$white'/> </Text>
                                        </Button>

                                    </FormControl>

                                    <FormControl
                                        mt={10}
                                        size="md"
                                        isRequired={true}
                                    >
                                        <FormControlLabel mb="$1">
                                            <FormControlLabelText color='$white'>Comprobante</FormControlLabelText>
                                        </FormControlLabel>
                                        <Input>
                                            <InputField onChangeText={(text) => setComprobante(text)} color='$white' type="text" value={comprobante} placeholder="Ingrese el nro de comprobante" />
                                        </Input>
                                    </FormControl>

                                    <FormControl
                                        mt={10}
                                        size="md"
                                        isRequired={true}
                                    >
                                        <FormControlLabel mb="$1">
                                            <FormControlLabelText color='$white'>Importe</FormControlLabelText>
                                        </FormControlLabel>
                                        <Input>
                                            <InputField onChangeText={(text) => setImporte(text)} color='$white' type="number" value={importe} placeholder="Ingrese el importe ej:  50215.93" keyboardType='number-pad' />
                                        </Input>
                                    </FormControl>

                                    <Button
                                        mt={40}
                                        size="md"
                                        variant="outline"
                                        action="primary"
                                        title="Salir" onPress={() => enviarAviso()}>
                                        <ButtonText color="$white">Enviar Aviso</ButtonText>
                                    </Button>


                                </Box>

                            </Box>
                        }

                    </Box>

                    <Box
                        sx={{
                            '@base': {
                                flexDirection: 'column',
                            },
                            '@md': {
                                flexDirection: 'row',
                            },
                        }}
                    >

                        <Button
                            mt={25}
                            size="md"
                            variant="outline"
                            action="primary"

                            title="Salir" onPress={() => volver()}>

                            <ButtonText color="$white">Volver</ButtonText>
                        </Button>

                    </Box>
                </Box>



                <SafeAreaProvider>

                    <View style={{ justifyContent: 'center', flex: 1, alignItems: 'center' }}>

                        <DatePickerModal
                            locale="es"
                            mode="single"
                            visible={open}
                            onDismiss={onDismissSingle}
                            date={fecha}
                            onConfirm={onConfirmSingle}
                        />
                    </View>
                
                </SafeAreaProvider>


            </ScrollView>
        </Box>




    );
}

export default AvisoPago;