import React, { useContext, useState, useEffect } from 'react';
import { Image, StyleSheet, View, ScrollView, ActivityIndicator, TouchableOpacity } from 'react-native';
import { AuthContext } from '../context/AuthContext';
import { AxiosContext } from '../context/AxiosContext';
import Spinner from '../componentes/Spinner';

import { Box, GluestackUIProvider, Text, Button, ButtonText } from '@gluestack-ui/themed';
import Gradient from '../assets/Icons/Gradient';
import DocumentData from '../assets/Icons/DocumentData';
import Bell from '../assets/Icons/Bell';
import Mail from '../assets/Icons/Mail';
import FeatureCard from '../componentes/FeatureCard';
import UnidadCard from '../componentes/UnidadCard';
import baseConfig from '../config';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { clearLastLoginData, getLastLoginData, storeUnidad } from '../context/LoginStorage';


const Unidades = (props) => {

  const axiosContext = useContext(AxiosContext);
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [lastLoginData, setLastLoginData] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [unidadesLoaded, setUnidadesLoaded] = useState(false);

  const loadLastLoginData = async () => {
    const lastLoginData = await getLastLoginData();
    setLastLoginData(lastLoginData);
  }

  useEffect(() => {
    loadLastLoginData();
    loadUnidades();
  }, []);

  const loadUnidades = async () => {
    setLoading(true);
    try {

      const tenantName = await AsyncStorage.getItem('tenant');
      let url = baseConfig.pre + tenantName + baseConfig.apiURL + '/unidades';

      const response = await axiosContext.authAxios.post(url);
      setUnidades(response.data);
      //console.log(response.data);
      setLoading(false);
      setUnidadesLoaded(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const selectUnidad = async (unidad) => {
    await storeUnidad(unidad).then(() => {
      props.setPage('unidad');
    });
  }

  const logout = async () => {
    await clearLastLoginData().then(() => {
      authContext.logout();
    });
  }

  return (
    <Box flex={1} backgroundColor="$black">
      <ScrollView
        style={{ height: '60%' }}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Box
          position="absolute"
          sx={{
            '@base': {
              h: 500,
              w: 500,
            },
            '@lg': {
              h: 700,
              w: 700,
            },
          }}
        >
          <Gradient />
        </Box>
        <Box
          height="60%"
          sx={{
            '@base': {
              my: '$16',
              mx: '$5',
              height: '80%',
            },
            '@lg': {
              my: '$24',
              mx: '$32',
            },
          }}
          justifyContent="space-between"
          alignItems="center"
        >


          <Box
            justifyContent="center"
            alignItems="center">

            <Text color="$white" fontWeight="$normal">
              Unidades Funcionales
            </Text>

            {loading ? <ActivityIndicator size="large" color="#ca50b0" /> : null}

            {loading ? null :
              <Box
                justifyContent="center"
                alignItems="center">
  
                <Text color="$grey" fontWeight="$normal" textAlign='center'>
                  Seleccione una unidad para continuar
                </Text>

                <Box mt={15} style={{ height: 500 }}>
                  {loading ? <ActivityIndicator size="large" color="#ca50b0" /> : null}
                  <ScrollView>

                    {unidades?.map((unidad) => {
                      return (
                        <TouchableOpacity onPress={() => { selectUnidad(unidad) }} key={unidad.id}>
                          <UnidadCard
                            name={unidad.piso + ' ' + unidad.departamento + ' - UF ' + unidad.uf.toString().padStart(3, '0')}
                            desc={unidad.edificio_direccion + ', ' + unidad.edificio_localidad}
                            saldo={unidad.saldo}
                          />
                        </TouchableOpacity>
                      )
                    }
                    )}

                    {!unidadesLoaded ? null : <View>
                      {unidades.length ? null : <Text>No encontramos unidades asociadas a tu correo.</Text>}
                    </View>
                    }

                  </ScrollView>
                </Box>
              </Box>
            }

          </Box>

          <Box
            sx={{
              '@base': {
                flexDirection: 'column',
              },
              '@md': {
                flexDirection: 'row',
              },
            }}
          >


            <Button
              size="md"
              variant="outline"
              action="primary"

              title="Salir" onPress={() => logout()}>

              <ButtonText color="$white">Volver</ButtonText>
            </Button>

          </Box>
        </Box>
      </ScrollView>
    </Box>


  );
}

export default Unidades;