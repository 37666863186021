
import Gradient from '../assets/Icons/Gradient';
import { ScrollView, View, ActivityIndicator, TouchableOpacity } from 'react-native';
import React, { useContext, useState, useEffect, useRef } from 'react';
import UnidadCard from '../componentes/UnidadCard';
import ExpensaCard from '../componentes/ExpensaCard';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from "react-native-toast-notifications";
import { AuthContext } from '../context/AuthContext';
import { AxiosContext } from '../context/AxiosContext';
import baseConfig from '../config';
import SelectDropdown from 'react-native-select-dropdown';

import { config } from "@gluestack-ui/config";
import { GluestackUIProvider } from '@gluestack-ui/themed';

import {
    Box, Text,
    FormControl, FormControlLabel, FormControlLabelText, FormControlError, FormControlErrorText, FormControlErrorIcon, FormControlHelper,
    FormControlHelperText, AlertCircleIcon, ButtonText, ButtonIcon, AddIcon, Button, UnlockIcon, TrashIcon,
    Select, SelectTrigger, SelectInput, SelectIcon, SelectPortal, SelectBackdrop, SelectContent, SelectDragIndicatorWrapper, SelectDragIndicator, SelectItem,
    ChevronDownIcon, Icon, AlertDialog, AlertDialogBackdrop, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, AlertDialogFooter, Center,
    showAlertDialog, AlertDialogOverlay, AlertDialogDescription, AlertDialogTitle, AlertDialogAction, AlertDialogCancel, AlertDialogIcon, AlertDialogLoading,
    Heading, CloseIcon, ButtonGroup, Textarea, TextareaField, Checkbox, CheckboxLabel, CheckboxLabelText, Radio, RadioGroup, RadioLabel, RadioLabelText

} from '@gluestack-ui/themed';

const Expensas = (props) => {

    const toast = useToast();
    const axiosContext = useContext(AxiosContext);
    const authContext = useContext(AuthContext);

    const volver = () => {
        props.setPage('unidad');
    }

    const [unidadData, setUnidadData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [expensasData, setExpensasData] = useState([]);
    const [expensasLoaded, setExpensasLoaded] = useState(false);
    const [paginas, setPaginas] = useState([]);
    const [paginaActual, setPaginaActual] = useState(1);
    const isFirstRender = useRef(true);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [expensaId, setExpensaId] = useState(null);


    useEffect(() => {
        loadUnidadData();
    }, [paginaActual]);

    const onChangePage = (pagina) => {
        setPaginaActual(pagina);
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return; // 👈️ return early if initial render
        }
        loadExpensas();
    }, [unidadData]); // 👈️ add state variables you want to track


    const loadUnidadData = async () => {
        const unidadData = await AsyncStorage.getItem('unidad');
        setUnidadData(JSON.parse(unidadData));
    }

    const mostrarAlerta = (expensa_id) => {
        setExpensaId(expensa_id);
        setShowAlertDialog(true);
    }

    const loadExpensas = async () => {

        setLoading(true);
        try {

            let data = {
                unidad_id: unidadData.id,
                page: paginaActual
            }

            const tenantName = await AsyncStorage.getItem('tenant');
            let url = baseConfig.pre + tenantName + baseConfig.apiURL + '/expensas';

            const response = await axiosContext.authAxios.post(url, data);

            if (response.data.success == false) {
                setLoading(false);
                setExpensasLoaded(false);
                return;
            }

            setExpensasData(response.data.data);

            //crear una lista de paginas para el paginado
            let paginas = [];
            for (let i = 1; i <= response.data.last_page; i++) {
                paginas.push({ key: i, pagina: 'Pagina ' + i });
            }

            setPaginas(paginas);

            setLoading(false);
            setExpensasLoaded(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const imprimirExpensa = async () => {

        setShowAlertDialog(false);
        setLoading(true);

        const tenantName = await AsyncStorage.getItem('tenant');
        let url = baseConfig.pre + tenantName + baseConfig.apiURL + '/expensas/imprimir';

        const data = {
            'expensa_id': expensaId,
            'unidad_id': unidadData.id
        }

        try {
            const response = await axiosContext.authAxios.post(url, data);

            if (response.data.success == true) {

                toast.show('La expensa sera enviada a tu correo', {
                    type: "success",
                });

            }

        } catch (error) {
            toast.show('No se pudo enviar el aviso, intentalo mas tarde', {
                type: "warning",
            });
        }
        finally {
            setLoading(false);
        }
    }


    return (
        <GluestackUIProvider config={config}>

        <Box flex={1} backgroundColor="$black">
            <ScrollView
                style={{ height: '60%' }}
                contentContainerStyle={{ flexGrow: 1 }}
            >
                <Box
                    position="absolute"
                    sx={{
                        '@base': {
                            h: 500,
                            w: 500,
                        },
                        '@lg': {
                            h: 700,
                            w: 700,
                        },
                    }}
                >
                    <Gradient />
                </Box>
                <Box
                    height="60%"
                    sx={{
                        '@base': {
                            my: '$16',
                            mx: '$5',
                            height: '80%',
                        },
                        '@lg': {
                            my: '$24',
                            mx: '$32',
                        },
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                >


                    <Box
                        justifyContent="center"
                        alignItems="center">

                        <Text color="$white" fontWeight="$normal">
                            EXPENSAS
                        </Text>

                        <UnidadCard
                            name={unidadData?.piso + ' ' + unidadData?.departamento + ' - UF ' + unidadData?.uf?.toString().padStart(3, '0')}
                            desc={unidadData?.edificio_direccion + ', ' + unidadData?.edificio_localidad}
                            saldo={unidadData?.saldo}
                        />

                        {loading ? <ActivityIndicator size="large" color="#ca50b0" /> : null}

                        {loading ? null :
                            <Box
                                justifyContent="center"
                                alignItems="center">
                                <Text color="$white" fontWeight="$normal" textAlign='center'>
                                    Lista de expensas emitidas
                                </Text>

                                <Box mt={10}>
                                    <SelectDropdown
                                        buttonStyle={{ width: 300, backgroundColor: 'rgba(255,255,255,0.1)', borderRadius: 15 }}
                                        buttonTextStyle={{ color: 'white' }}

                                        data={paginas}
                                        defaultButtonText={"Paginas"}
                                        onSelect={(selectedItem, index) => {
                                            onChangePage(selectedItem.key);
                                        }}
                                        buttonTextAfterSelection={(selectedItem, index) => {
                                            // text represented after item is selected
                                            // if data array is an array of objects then return selectedItem.property to render after item is selected
                                            return selectedItem.pagina
                                        }}
                                        rowTextForSelection={(item, index) => {
                                            // text represented for each item in dropdown
                                            // if data array is an array of objects then return item.property to represent item in dropdown
                                            return item.pagina
                                        }}
                                    />
                                </Box>


                                <Box mt={15} style={{ height: 300 }}>
                                    {expensasLoaded ? <ActivityIndicator size="large" color="#ca50b0" /> : null}
                                    <ScrollView >
                                        {expensasData?.map((expensa) => {
                                            return (
                                                <TouchableOpacity onPress={() => { mostrarAlerta(expensa.id) }} key={expensa.id}>
                                                    <ExpensaCard expensa={expensa} />
                                                </TouchableOpacity>
                                            )
                                        }
                                        )}
                                        {!expensasLoaded ? null : <View>
                                            {expensasData.length ? null : <Text>No encontramos expensas emitidas.</Text>}
                                        </View>
                                        }
                                    </ScrollView>
                                </Box>
                            </Box>
                        }

                    </Box>

                    <Box
                        sx={{
                            '@base': {
                                flexDirection: 'column',
                            },
                            '@md': {
                                flexDirection: 'row',
                            },
                        }}
                    >

                        <Button
                            size="md"
                            variant="outline"
                            action="primary"

                            title="Salir" onPress={() => volver()}>

                            <ButtonText color="$white">Volver</ButtonText>
                        </Button>

                    </Box>
                </Box>



                <Center h={25} mb={10}>
                        <AlertDialog
                            isOpen={showAlertDialog}
                            onClose={() => {
                                setShowAlertDialog(false)
                            }}
                        >
                            <AlertDialogBackdrop />
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <Heading size="lg">Envio de expensas</Heading>
                                    <AlertDialogCloseButton>
                                        <Icon as={CloseIcon} />
                                    </AlertDialogCloseButton>
                                </AlertDialogHeader>
                                <AlertDialogBody>
                                    <Text size="sm">
                                        ¿Confirma enviar por email las expensas?
                                    </Text>
                                </AlertDialogBody>
                                <AlertDialogFooter>
                                    <ButtonGroup space="lg">
                                        <Button
                                            variant="outline"
                                            action="secondary"
                                            onPress={() => {
                                                setShowAlertDialog(false)
                                            }}
                                        >
                                            <ButtonText>Cancelar</ButtonText>
                                        </Button>
                                        <Button
                                            bg="$success600"
                                            action="negative"
                                            onPress={() => {
                                                imprimirExpensa();
                                            }}
                                        >
                                            <ButtonText>Enviar</ButtonText>
                                        </Button>
                                    </ButtonGroup>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                    </Center>

            </ScrollView>
        </Box>
        </GluestackUIProvider>


    );
}

export default Expensas;