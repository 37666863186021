
import {
    Box, GluestackUIProvider, Text, Button, ButtonText, Input, InputField, FormControlLabel, FormControlLabelText, FormControl, CalendarDaysIcon

} from '@gluestack-ui/themed';
import Gradient from '../assets/Icons/Gradient';
import { ScrollView, View, ActivityIndicator, TouchableOpacity, StyleSheet } from 'react-native';
import React, { useContext, useState, useEffect, useRef } from 'react';
import UnidadCard from '../componentes/UnidadCard';
import NotificacionCard from '../componentes/NotificacionCard';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from "react-native-toast-notifications";
import { AuthContext } from '../context/AuthContext';
import { AxiosContext } from '../context/AxiosContext';
import baseConfig from '../config';
import Modal from "react-native-modal";

import { useWindowDimensions } from 'react-native';
import RenderHtml from 'react-native-render-html';



const Notificaciones = (props) => {

    const toast = useToast();
    const axiosContext = useContext(AxiosContext);
    const authContext = useContext(AuthContext);
    const { width } = useWindowDimensions();

    const volver = () => {
        props.setPage('unidad');
    }

    const [unidadData, setUnidadData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [notificaciones, setNotificaciones] = useState([]);
    const [notificacionesLoaded, setNotificacionesLoaded] = useState(true);
    const isFirstRender = useRef(true);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleModal = () => setIsModalVisible(() => !isModalVisible);
    const [modalText, setModalText] = useState('');

    useEffect(() => {
        loadUnidadData();
    }, []);

    const abrirModal = (notificacion) => {
        setIsModalVisible(true);

        const source = {
            html: `<div>` + notificacion.descripcion + `</div>`
        }
        setModalText(source);
    }


    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return; // 👈️ return early if initial render
        }
        loadNotificaciones();
        setLoading(false);
    }, [unidadData]); // 👈️ add state variables you want to track


    const loadUnidadData = async () => {
        setLoading(true);
        const unidadData = await AsyncStorage.getItem('unidad');
        setUnidadData(JSON.parse(unidadData));
    }

    const loadNotificaciones = async () => {

        try {

            let data = {
                unidad_id: unidadData.id
            }

            const tenantName = await AsyncStorage.getItem('tenant');
            let url = baseConfig.pre + tenantName + baseConfig.apiURL + '/notificaciones';

            const response = await axiosContext.authAxios.post(url, data);

            if (response.data.success == false) {
                setNotificacionesLoaded(false);
                return;
            }

            setNotificaciones(response.data);

            setNotificacionesLoaded(false);
        } catch (error) {
            console.log(error);
            setNotificacionesLoaded(false);
        }
    };




    return (
        <Box flex={1} backgroundColor="$black">
            <ScrollView
                style={{ height: '60%' }}
                contentContainerStyle={{ flexGrow: 1 }}
            >
                <Box
                    position="absolute"
                    sx={{
                        '@base': {
                            h: 500,
                            w: 500,
                        },
                        '@lg': {
                            h: 700,
                            w: 700,
                        },
                    }}
                >
                    <Gradient />
                </Box>
                <Box
                    height="60%"
                    sx={{
                        '@base': {
                            my: '$16',
                            mx: '$5',
                            height: '80%',
                        },
                        '@lg': {
                            my: '$24',
                            mx: '$32',
                        },
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                >


                    <Box
                        justifyContent="center"
                        alignItems="center">

                        <Text color="$white" fontWeight="$normal">
                        NOTIFICACIONES
                        </Text>

                        <UnidadCard
                            name={unidadData?.piso + ' ' + unidadData?.departamento + ' - UF ' + unidadData?.uf?.toString().padStart(3, '0')}
                            desc={unidadData?.edificio_direccion + ', ' + unidadData?.edificio_localidad}
                            saldo={unidadData?.saldo}
                        />

                        {loading ? <ActivityIndicator size="large" color="#ca50b0" /> : null}

                        {loading ? null :
                            <Box
                                justifyContent="center"
                                alignItems="center">
                                <Text color="$white" fontWeight="$normal" textAlign='center'>
                                Lista de notificaciones
                                </Text>

                           
                                <Box mt={15} style={{ height: 300 }}>
                                    {notificacionesLoaded ? <ActivityIndicator size="large" color="#ca50b0" /> : null}
                                    <ScrollView>
                                        {notificaciones?.map((notificacion) => {
                                            return (
                                                <TouchableOpacity onPress={() => { abrirModal(notificacion) }} key={notificacion.id}>
                                                    <NotificacionCard notificacion={notificacion} />
                                                </TouchableOpacity>
                                            )
                                        }
                                        )}
                                        {notificacionesLoaded ? null : <View> 
                                            {notificaciones.length > 0 ? null
                                                : <Text borderWidth={1} borderColor="$blueGray400"  m="$2" p="$4" color="$white">
                                                No se encontraron notificaciones
                                                </Text> 
                                            }
                                        </View>
                                        }
                                    </ScrollView>
                                </Box>
                            </Box>
                        }

                    </Box>

                    <Box
                        sx={{
                            '@base': {
                                flexDirection: 'column',
                            },
                            '@md': {
                                flexDirection: 'row',
                            },
                        }}
                    >

                        <Button
                            size="md"
                            variant="outline"
                            action="primary"

                            title="Salir" onPress={() => volver()}>

                            <ButtonText color="$white">Volver</ButtonText>
                        </Button>

                    </Box>
                </Box>


                <Modal isVisible={isModalVisible}>
                <Box
                    height="40%"
                    sx={{
                        '@base': {
                            my: '$16',
                            mx: '$5',
                            height: '40%',
                        },
                        '@lg': {
                            my: '$24',
                            mx: '$32',
                        },
                    }}
                    backgroundColor='rgba(255,255,255,0.9)'
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <ScrollView>
                    <RenderHtml
                    contentWidth={width}
                    source={modalText}
                        />
                    </ScrollView>

                    <Button
                        size="md"
                        variant="outline"
                        action="primary"
                        title="Salir" onPress={handleModal}>
                        <ButtonText color="$black">Cerrar</ButtonText>
                    </Button>
                
                </Box>
            </Modal>


            </ScrollView>
        </Box>


    );
}

export default Notificaciones;

